import Pusher from 'pusher-js'

export const initPusher = (channelID) => {
    const APP_KEY = process.env.REACT_APP_PUSHER_KEY
    const APP_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER

    const pusher = new Pusher(APP_KEY, {
        cluster: APP_CLUSTER,
    });
    
    const channel = pusher.subscribe(channelID)
    return channel
}

export const unBindPusherChannel = (channel, eventName, channelID) => {
    channel.unbind(eventName)
    channel.unsubscribe(channelID)
    channel.disconnect()
}