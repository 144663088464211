/* eslint-disable */
import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import LuggageIcon from "@mui/icons-material/Luggage";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import BaggageContainer from "./BaggageContainer";
import { getBaggageDataObject, isEmptyPassengerList } from "./BaggageService";
import useLocalStorage, {
  APP_LOCAL_STORAGE_KEYS,
} from "../../hooks/useLocalstorage";
import { isEmptyObj } from "../../common/Utils";
import { JOURNEY_TYPE } from "../StationSelection/StationSelection";

const ExtraFacilities = ({
  showBaggageResetMsg,
  showErrorPopUp,
  baggageOptions,
  passengerDetails,
  bottomButtonRef,
  setOpenBagageSlider,
  openBagageSlider,
  baggageSelectionData,
  setBaggageSelectionData,
}) => {
  const { get: getLocalStorageValue } = useLocalStorage();

  const flightDataOneWay = getLocalStorageValue(
    APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsOneWay
  );
  const flightDataRound = getLocalStorageValue(
    APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsRound
  );
  const airlines = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.airlines);
  const initialFormData = getLocalStorageValue(
    APP_LOCAL_STORAGE_KEYS.initialFormData
  );
  const baggageDataObject = getBaggageDataObject(
    baggageOptions,
    flightDataOneWay,
    flightDataRound,
    initialFormData,
    airlines
  );

  const isNoBaggageOptions =
    isEmptyObj(baggageDataObject?.[JOURNEY_TYPE.OneWay]) &&
    isEmptyObj(baggageDataObject?.[JOURNEY_TYPE.RoundTrip]);

  const toggleSlider = () => {
    if (isNoBaggageOptions) {
      showErrorPopUp({
        showPopUp: true,
        message: "Maaf, maskapai ini tidak menyediakan tambahan bagasi",
      });
      return;
    }
    if (isEmptyPassengerList(passengerDetails)) {
      showErrorPopUp({
        showPopUp: true,
        message: "Mohon lengkapi data penumpang terlebih dahulu",
      });
      return;
    }
    if (!openBagageSlider && bottomButtonRef) {
      bottomButtonRef.current.scrollIntoView();
    }
    setOpenBagageSlider(!openBagageSlider);
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <Grid container spacing={3}>
        <Grid xs={10} item>
          <Typography
            children={"Fasilitas Ekstra"}
            className={"inputSectionLabels"}
          />
        </Grid>
        <Grid md={2} item>
          <KeyboardArrowUpIcon
            style={{ color: "#1A1A1A", position: "relative", left: "75%" }}
          ></KeyboardArrowUpIcon>
        </Grid>
      </Grid>

      <Paper
        id="baggage-info"
        onClick={toggleSlider}
        className={"paperCards"}
        style={{ paddingTop: "15px" }}
      >
        <Grid container spacing={0.5} style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <Grid item>
              <LuggageIcon style={{ color: "#1A1A1A" }}></LuggageIcon>
            </Grid>
            <Grid item xs={8} md={8}>
              <Typography
                children={"Bagasi"}
                className={"inputSectionLabels"}
                style={{ marginTop: "2px" }}
              />
            </Grid>
          </div>
          <Grid item md={3} style={{ display: "flex", cursor: "pointer", justifyContent: 'center' }}>
            <AddIcon
              style={{
                color: "#1A1A1A",
                fontSize: "15px",
                fontWeight: "bold",
                margin: "2px 4px 0px 0px"
              }}
            />
            <Typography
              children={"Pesan"}
              style={{
                color: "#1A1A1A",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Typography
            children={"Tambah kapasitas barang bawaanmu."}
            style={{ color: "#615A5A", fontSize: "12px", marginTop: "2px" }}
          />
        </Grid>
        {showBaggageResetMsg && (
          <Grid container spacing={0}>
            <Typography
              children={
                "Pemilihan bagasi telah diatur ulang, silakan pilih kembali"
              }
              style={{
                color: "rgb(203, 0, 0)",
                fontSize: "12px",
                marginTop: "2px",
              }}
            />
          </Grid>
        )}
      </Paper>

      {/* ************Baggage Options**************** */}
      <>
        {openBagageSlider && (
          <BaggageContainer
            passengerDetails={passengerDetails}
            baggageDataObject={baggageDataObject}
            openBagageSlider={openBagageSlider}
            toggleSlider={toggleSlider}
            setBaggageSelectionData={setBaggageSelectionData}
            baggageSelectionData={baggageSelectionData}
          />
        )}
      </>
    </div>
  );
};

export default ExtraFacilities;
