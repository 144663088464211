import React from "react";

import { FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

const SliderButton = ({ returnTicket, setReturnTicket }) => {
  return (
    <div className="text-right" style={{ width: "30%" }}>
      <div>
        <Typography
          className={"inputLabels"}
          children={"Pulang Pergi"}
          id="PulangPergi-label"
        />
      </div>
      <div>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} />}
          label=""
          style={{
            marginLeft: 0,
            marginRight: 0,
            padding: "5px 15px 0px 15px",
          }}
          onClick={() => setReturnTicket(!returnTicket)}
        />
      </div>
    </div>
  );
};

export default SliderButton;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 28,
  padding: 0,
  "&.MuiSwitch-root": {
    margin: 0,
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    marginLeft: 2,
    marginRight: 2,
    marginTop: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#F0EDED" : "#1a1a1a",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 100,
    backgroundColor: theme.palette.mode === "light" ? "#F0EDED" : "#1a1a1a",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
