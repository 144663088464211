/* eslint-disable */
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import Icon from '@mui/material/Icon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, Paper, Typography } from '@mui/material';
import { passengerClassMap } from '../../common/Utils';
import moment from 'moment';

const PlainTicketCard = ({flightData, passengerClass}) => {
    return Object.values(flightData).map((flight, index) => (
        <Paper key={index} id={"selectedStationTicketCard"} className={'paperCards'} style={{ padding: "0" }} >
            <div id={"selectedStationTicketCard-date"} style={{ padding: "16px 16px 8px 16px", borderRadius: "8px 8px 0 0", display: "flex", alignItems: "center" }} >
                <FlightTakeoffOutlinedIcon style={{ color: "#555555", fontSize: "18px", marginRight: "6px" }} />
                <Typography className='flightContainer' id={"selectedStationTicketCard-date-value"} children={`${moment(flight.date, "MM-DD-YYYY").locale('id').format("ddd, DD MMM YYYY")}`} />
            </div>
            <Grid container sx={{ padding: '4px 4px 0px 18px' }}>
                <Grid item > <Typography sx={{ fontWeight: '700', fontSize: '14px' }} children={flight.depAirportCode} /></Grid>
                <Grid item sx={{ marginLeft: '2px', marginTop: '2px', width: '18px' }}><ArrowForwardIcon fontSize='18' /></Grid>
                <Grid item> <Typography sx={{ fontWeight: '700', fontSize: '14px' }} children={flight.arrAirportCode} /></Grid>
            </Grid>
            <Grid container sx={{ padding: '4px 4px 0px 18px',color: '#555555',marginBottom:'5px' }}>
                <Grid item > <Typography sx={{ fontWeight: '400', fontSize: '12px'}} children={flight.depTime} /></Grid>
                <Grid item sx={{ marginLeft: '2px', position: 'relative',bottom:'1px',paddingLeft:'2px',paddingRight:'2px',fontFamily: 'TT Interphases' }}>-</Grid>
                <Grid item> <Typography sx={{ fontWeight: '400', fontSize: '12px',fontFamily: 'TT Interphases' }} children={flight.arrTime} /></Grid>
            </Grid>
            <Grid container sx={{ padding: '4px 4px 0px 18px',marginBottom:'5px'}}>
                <Grid item><img  src={flight.airlineLogo} style={{width:'10px',height:'10px',marginRight:'4px',padding:'3px',borderRadius:'50%',border:'1px solid lightgrey', objectFit: "contain"}} /></Grid>
                <Grid item > <Typography sx={{ fontWeight: '400', fontSize: '12px',color: '#1A1A1A',fontFamily: 'TT Interphases' }} children={flight.airlineName} /></Grid>
                <Grid item sx={{ marginLeft: '2px', position: 'relative',bottom:'3px',paddingLeft:'2px',paddingRight:'2px' }}> <Icon sx={{ fontSize: '5px', color:'#615A5A' }}>circle</Icon></Grid>
                <Grid item> <Typography sx={{ fontWeight: '400', fontSize: '12px',color: '#615A5A',fontFamily: 'TT Interphases' }} children={passengerClassMap[passengerClass]} /></Grid>
            </Grid>
            <Grid container sx={{ padding: '4px 4px 0px 18px',marginBottom:'10px',paddingBottom:'10px'}}>
                <Grid item><CheckCircleIcon sx={{color:'#5CD182',fontSize:'12px'}}></CheckCircleIcon></Grid>
                <Grid item><Typography sx={{marginLeft:'2px',fontSize: '10px',color: '#5CD182',fontFamily: 'TT Interphases' }} children={'Bisa Refund'} /></Grid>
            </Grid>
        </Paper>
    ));
};

export default PlainTicketCard;