import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { priceFormatter } from '../../../common/Utils'
import Footer from '../../../CommonComponents/Footer/Footer'
import { checkPaymentStatus } from '../../../api/ApiUtils';
import { PopUpModal } from '../../../CommonComponents/PopUp/PopUpModal';
import { initPusher, unBindPusherChannel } from '../../../common/pusher';
import Loader from '../../../CommonComponents/Loader/Loader';
import moment from 'moment';

const Container = styled('div')(({open}) => { 
  const containerStyle = {
    height: 'calc(100vh - 84px)',
    padding: '0 1rem',
    position: 'relative',
    overflow: 'hidden',
    overflowY: 'auto'
  }
  if(open){ 
    containerStyle.background = '#66666699';
    containerStyle.opacity = 0.5
  }
  return containerStyle
})

const Logo = styled('div')({
  textAlign: 'center',
  marginTop: '1.5rem',
  '& img': {
    height: '1.4rem',
    margin: '0.8rem 0.3rem'
  }
})

const VirtualAccountID = styled('div')({
  textAlign: 'center',
  margin: '1rem 0',
})

const VirtualAccountIDText = styled(Typography)(({type}) => {
  const textStyle = {
    fontSize: '0.875rem',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '1.25rem',
  }
  if(type === 'title') {
    textStyle.color = '#1F1F1F';
    textStyle.fontWeight = '400';
  }
  if(type === 'paymentID') {
    textStyle.margin = '0.5rem 0';
    textStyle.fontSize = '1.5rem';
    textStyle.lineHeight = '2rem';
    textStyle.color = '#1F1F1F';
  }
  if(type === 'copyID') {
    textStyle.color = '#19A9E1';
    textStyle.cursor = 'pointer'
  }
  if(type === 'message') {
    textStyle.color = '#167F50';
    textStyle.fontWeight = 400;
  }

  return textStyle
})

const TransactionValidity = styled(Typography)(() => {
  const textStyle = {
    fontSize: '0.875rem',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '1.25rem',
  }
  return {
    textAlign: 'center',
    color: '#4D3200',
    ...textStyle,
    fontWeight: '400',
    borderRadius: '0.5rem',
    padding: '0.5rem',
    background: '#FFEECC',
    '& span': {
      fontWeight: '600'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem'
  }
})
const TransactionDetails = styled('div')(() => {
  const textStyle = {
    fontSize: '0.875rem',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '1.25rem',
  }
  return {
    border: '1px solid #DFE2E6',
    borderRadius: '0.75rem',
    padding: '1rem',
    margin: '1.5rem 0',
    '& > p': {
      ...textStyle,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      marginBottom: '1rem'
    }
  }
})
const TextContainer = styled('div')(({totalprice}) => {
  const textStyle = {
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
    marginBottom: '0.5rem'
  }
  if(totalprice === 'ID Pesanan') textStyle.marginBottom = 0;
  if(totalprice === 'Total Jumlah') {
    textStyle.fontSize = '1rem'
    textStyle.fontWeight = '700'
    textStyle.lineHeight = '1.5rem'
    textStyle.marginBottom = 0
  }
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': textStyle
  }
})

const FAQ = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  color: '#19A9E1',
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '1.25rem',
})

const CheckStatusButton = styled(Button)({
  color: '#333',
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '1.5rem',
  padding: '0.75rem 1rem',
  borderRadius: '0.5rem',
  borderColor: '#0000001A',
  marginTop: '2rem',
  marginBottom: '1rem',
  textTransform: 'capitalize',
})

const InfoOrder = styled('div')({
  padding: '12px',
  background: '#E8F7FC',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.10)',
  '& > p': {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px'
  }
})


const PaymentConfirmation = () => {  

  const StaticUrl = process.env.REACT_APP_STATIC_URL;
  
  const page = window.location.pathname.includes('paymentConfirmation')
  useEffect(() => {
    if(page) {
      const onConfirmRefresh = function (event) {
        event.preventDefault();
        return event.returnValue = "";
      }
      const onConfirmBack = function (event) {
        event.preventDefault();
        window.history.go(1)
        return event.returnValue = "";
      }
      window.addEventListener('popstate', onConfirmBack, { capture: true });
      window.addEventListener("beforeunload", onConfirmRefresh, { capture: true });
    }
    return () => {
      window.removeEventListener('popstate', function(event) {
        window.history.go(0)
        return
      });
      window.removeEventListener('beforeunload', function(event) {
        return event.returnValue = "";
      });
    };
  }, [page]);

  const navigate = useNavigate()
  const { totalamount, bookingId, bankName, /*bankCode,*/ enrollmentPaymentId, virtualAccountNo, correlationId } = useParams()

    // CREATE PUSHER CONNECTION HERE
  const handlePusher = () => {
    const pusherChannel = initPusher(bookingId)
    pusherChannel.bind('bpjs-va-payment', (response) => {
        const { success, responseCode } = response
        if(success && responseCode === 200) {
            unBindPusherChannel(pusherChannel, 'bpjs-va-payment', bookingId)
            navigate(`/paymentSuccess/${bookingId}/${totalamount}/${bankName}/${enrollmentPaymentId}`) 
        }
        else if(success && seconds === 0 && responseCode===299) {
          setIsPaymentProcessing(true)
        }
        else if(!success && responseCode===402) {
          setTimeoutError(true)
        }
        else if(!success && responseCode===199) {
          setPaymentError(true) 
        }
    })
  }
  // START PUSHER TO RECEIVE THE PAYMENT STATUS RESPONSE AND SET THE END TIME FOR VA
  useEffect(() => { 
    const vaENDTime = JSON.parse(localStorage.getItem("VA_END_TIME"))
    if(!vaENDTime) {
      localStorage.setItem("VA_END_TIME", JSON.stringify(moment().add(1800, 'seconds').format('hh:mm')))
      handlePusher() 
    }
    else {
      const endTime = moment(vaENDTime, 'h:mm')
      const currentTime = moment().format('h:mm')
      const remainingTime = (endTime.subtract(currentTime).format('mm') * 60)
      if(remainingTime > 0)
        setSeconds(remainingTime)
      else
        setSeconds(0)
    }
    // eslint-disable-next-line
  }, [])

  const bankDetails = {
    'Metode Pembayaran': 'Virtual Account',
    'Nama Bank': bankName,
    'ID Pesanan': `${bookingId}`,
    'Total Jumlah': `Rp ${priceFormatter(parseInt(totalamount))}`,
  }
  const customerDetails = JSON.parse(localStorage.getItem('KAI'));
  const customerContactDetails = customerDetails.customerContactDetails;
  const customerName = customerContactDetails.firstName + ' ' + customerContactDetails.lastName; 
  const customerPhoneNo = customerContactDetails.phoneCode + customerContactDetails.phoneNo
  const customerEmail = customerContactDetails.email
  const orderDetails = {
    'ID Pesanan': `${bookingId}`,
    'Nama Pemesan': customerName,
    'No. Telp Pemesan': `+${customerPhoneNo}`,
    'Email Pemesan': customerEmail
  }

  const logo = localStorage.getItem('vaLogo')

  const [showClipBoardMessage, setShowClipBoardMessage] = useState(false);
  const handleCopyToClipboard = async(paymentID, viewMessage=true) => {
    await navigator.clipboard.writeText(paymentID)
    if(viewMessage) {
      setShowClipBoardMessage(true)
      setTimeout(() => {
        setShowClipBoardMessage(false)
      }, 3000);
    }
  }

  const [openFAQ, setOpenFAQ] = useState(false);

  const [seconds, setSeconds] = useState(1800);
  const [timeoutError, setTimeoutError] = useState(false);
  
  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
      return () => {
        clearInterval(timer);
      }
    }
    if(seconds === 0) {
      handleCheckStatusAPI({isTimeOut: true})
    }
    // eslint-disable-next-line
  }, [seconds]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const remainingSeconds = timeInSeconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCheckStatusAPI = async({isTimeOut = false}) => {
    setLoading(true)
    const payload = {
      enrollmentId: parseInt(enrollmentPaymentId),
      virtualAccountNo,
      correlationId,
      isTimeOut: false
    }  
    if(isTimeOut===true || seconds===0) payload.isTimeOut = true
    
    const checkPaymentStatusResponse = await checkPaymentStatus(payload)
    const { success, responseCode } = checkPaymentStatusResponse.data
    
    if(success && responseCode===200) {
      localStorage.removeItem('VA_END_TIME')
      navigate(`/paymentSuccess/${bookingId}/${totalamount}/${bankName}/${enrollmentPaymentId}`) 
    }
    else if(success && (seconds === 0 || responseCode===299)) {
      setIsPaymentProcessing(true)
    }
    else if(!success && responseCode===402) {
      localStorage.removeItem('VA_END_TIME')
      setTimeoutError(true)
    }
    else if(!success && responseCode===199) {
      localStorage.removeItem('VA_END_TIME')
      setPaymentError(true) 
    }
    setLoading(false)
  }

  const handlePopUpClose = () => {
    window.parent.postMessage('reload', StaticUrl);
  }

  const displayVirtualAccountwithTimer = () => {
    return (
      <Fragment>
        <VirtualAccountID>
          <VirtualAccountIDText children={'Nomor Virtual Account'} type={'title'} />
          <VirtualAccountIDText id={'virtualAccountNumber'} children={virtualAccountNo} type={'paymentID'} />
          <VirtualAccountIDText id={'virtualAccountNumberCopy'} type={'copyID'} onClick={()=>handleCopyToClipboard(virtualAccountNo)}>
            Salin <img src={'/images/copyToClipBoard.svg'} alt='copy to clipboard' />
          </VirtualAccountIDText>
          <VirtualAccountIDText children={showClipBoardMessage && 'copied to clipboard !'} type={'title'} />
        </VirtualAccountID>
        <TransactionValidity>
          <ErrorOutlineRoundedIcon /> Selesaikan Pembayaran dalam  <span id={'virtualAccountValidTime'}>{formatTime(seconds)}</span>
        </TransactionValidity>
      </Fragment>
    )
  }

  if(paymentError) {
    return (
      <Container>
        <Logo>
          <img src={'/images/warning.svg'} alt={'cancelled'} style={{height: '96px'}} />
        </Logo>
        <VirtualAccountID>
          <VirtualAccountIDText id={'ticketFailedTitle'} children={'Issue Tiket Gagal'} type={'paymentID'} />
          <VirtualAccountIDText id={'ticketFailedMessage'} children={'Mohon hubungi [Ops_Phone_number]'} type={'title'} />
        </VirtualAccountID>
        <TransactionDetails>
            <Typography>Detail Pemesanan</Typography>
            {Object.keys(orderDetails).map((orderDetailsKey, i) => 
              <Fragment key={i}> 
                <TextContainer >
                  <Typography id={orderDetailsKey} children={orderDetailsKey} color={'#666'} />
                  {i === 0 ?
                    <Typography id={orderDetailsKey+'_value'} color={'#333'} >
                      {orderDetails[orderDetailsKey]} 
                      <img 
                        src={'/images/copyToClipBoard.svg'} 
                        alt='copy to clipboard' 
                        style={{marginLeft:'16px'}}
                        onClick={()=>handleCopyToClipboard(orderDetails[orderDetailsKey])}
                      />
                    </Typography>
                    :
                    <Typography id={orderDetailsKey+'_value'} children={orderDetails[orderDetailsKey]} color={'#333'} />
                  }
                </TextContainer>
              </Fragment>
            )}
          </TransactionDetails>

          <CheckStatusButton
            id={'checkStatusButton'}
            children={'Kembali ke laman utama'}
            variant={'outlined'}
            fullWidth
            onClick={handlePopUpClose}
          />

          <Footer type={'poweredByAyoconnectAll'} /> 
      </Container>
    )
  }
  else
  return (
    <Fragment>
      <Loader loading={loading} />
      {timeoutError && <PopUpModal 
        open={timeoutError} 
        handlePopUpClose={handlePopUpClose}
        type={'VAPopup'}
        VAPopupProps={{
          imgURL: '/images/timeout.svg', 
          title: 'Virtual Account Kadaluarsa',
          message: 'Anda sudah melewati batas waktu pembayaran. mohon ulangi proses pemesanan', 
          action: 'Pesan Ulang'
        }}
      />}
      
      {
        isPaymentProcessing ? 
        <Container>
          <Logo>
            <img src={'/images/sandclock.svg'} alt={'processing'} style={{height: '96px'}} />
          </Logo>
          <VirtualAccountID>
            <VirtualAccountIDText id={'ticketProcessingTitle'} children={'Menunggu Pembayaran'} type={'paymentID'} />
            <VirtualAccountIDText id={'ticketProcessingMessage'} children={'Kami masih menunggu pembayaran anda, mohon selesaikan pembayaran anda.'} type={'title'} />
          </VirtualAccountID>

          {displayVirtualAccountwithTimer()}

          <TransactionDetails>
            <Typography>Detail Pemesanan</Typography>
            {Object.keys(orderDetails).map((orderDetailsKey, i) => 
              <Fragment key={i}> 
                  {i<3 && <TextContainer >
                    <Typography id={orderDetailsKey} children={orderDetailsKey} color={'#666'} />
                    {i === 0 ?
                      <Typography id={orderDetailsKey+'_value'} color={'#333'} >
                        {orderDetails[orderDetailsKey]} 
                        <img 
                          src={'/images/copyToClipBoard.svg'} 
                          alt='copy to clipboard' 
                          style={{marginLeft:'16px'}}
                          onClick={()=>handleCopyToClipboard(orderDetails[orderDetailsKey], false)}
                        />
                      </Typography>
                      :
                      <Typography id={orderDetailsKey+'_value'} children={orderDetails[orderDetailsKey]} color={'#333'} />
                    }
                  </TextContainer>}
              </Fragment>
            )}
          </TransactionDetails>

          <InfoOrder>
            <img src={'/images/info.svg'} alt='info' />
            <Typography 
              children={'Bila anda tidak menerima email dari kami setelah 30 menit, harap hubungi operator di [kontak_ops] dan lampirkan detil informasi diatas.'}
            />
          </InfoOrder>

          <CheckStatusButton
            id={'checkStatusButton'}
            children={'Cek Status Pembayaran'}
            variant={'outlined'}
            fullWidth
            startIcon={<CachedRoundedIcon htmlColor={'#333'} />}
            onClick={handleCheckStatusAPI}
          />

          <Footer type={'poweredByAyoconnectAll'} /> 
        </Container> 
        :
        <Container open={openFAQ}>
          <Logo>
            {logo ? <img src={logo} alt={'va logo'} /> : <Fragment />}
          </Logo>

          {displayVirtualAccountwithTimer()}

          <TransactionDetails>
            <Typography>Detail Transaksi</Typography>
            {Object.keys(bankDetails).map((bankDetailsKey, i) => 
              <Fragment key={i}> 
                  <TextContainer totalprice={bankDetailsKey}>
                    <Typography id={bankDetailsKey} children={bankDetailsKey} color={bankDetailsKey === 'Total Jumlah' ? '#333' : '#555'} />
                    <Typography id={bankDetailsKey+'_value'} children={bankDetails[bankDetailsKey]} color={bankDetailsKey === 'Total Jumlah' ? '#167F50' : '#333'} />
                  </TextContainer>
                  {i===2 && <Divider sx={{margin: '1rem 0'}} />}
              </Fragment>
            )}
          </TransactionDetails>

          <FAQ id={'FAQ_Drawer'} onClick={()=>setOpenFAQ(true)}>
            <HelpOutlineRoundedIcon htmlColor={'#19A9E1'} />
            Cara bayar menggunakan Virtual Account ?
          </FAQ>

          <CheckStatusButton
            id={'checkStatusButton'}
            children={'Cek Status Pembayaran'}
            variant={'outlined'}
            fullWidth
            startIcon={<CachedRoundedIcon htmlColor={'#333'} />}
            onClick={handleCheckStatusAPI}
          />

          <Footer type={'poweredByAyoconnect'} />        
        </Container>

      }      
      <FAQSection open={openFAQ} close={setOpenFAQ} />
    </Fragment>
  )
}

export default PaymentConfirmation

const FAQDrawer = styled('div')(({open}) => {
  const drawerStyle = {
    position: 'fixed',
    top: '20%',
    bottom: 0,
    right: 0,
    left: 0,
    background: '#FFF',
    maxWidth: '640px',
    margin: '0 auto',
    borderRadius: '1rem',
    opacity: 1,
    transition: 'top 0.25s'
  }
  if(!open) {
    drawerStyle.top = '100%'
  }
  return drawerStyle
})
const FAQContainer = styled('div')({
  padding: '1.5rem 1rem',
  height: '100%',
  overflowY: 'auto',
})

const Title = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '0.5rem',
  '& > p': {
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '2rem',
  }
})

const AccordionStyled = styled(Accordion)({
  margin: '1rem 0',
  boxShadow: 'none',
  border: '1px solid #DFE2E6',
  borderRadius: '0.5rem !important',
  '&::before':{
    background: 'none'
  },
  '& .MuiAccordionSummary-root': {
    padding: '1rem',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '& > p': {
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
      }
    },
  },
  '& .MuiAccordionDetails-root': {
    paddingRight: '1rem',
    paddingBottom: '1rem',
    paddingLeft: 0,
  },
  '& ol': {
    margin: 0,
    '& li': {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '0.9375rem',
      marginBottom: '0.6rem'
    }
  }
})

const FAQSection = ({open, close}) => (
  <FAQDrawer open={open}>
    <FAQContainer>
      
      <Title>
        <Typography children={'Cara bayar menggunakan Virtual Account?'} />
        <IconButton children={<CloseRoundedIcon />} onClick={()=>close(!open)} />
      </Title>

      <AccordionStyled>
        <AccordionSummary expandIcon={<ExpandMoreRoundedIcon htmlColor={'#333'} />}>
          <Typography children={'ATM'} />
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            {
              atm.map((data, i) => <li key={i}>{data}</li>)
            }
          </ol>
        </AccordionDetails>
      </AccordionStyled>

      <AccordionStyled>
        <AccordionSummary expandIcon={<ExpandMoreRoundedIcon htmlColor={'#333'} />}>
          <Typography children={'Aplikasi BNI Mobile'} />
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            {
              bniMobile.map((data, i) => <li key={i}>{data}</li>)
            }
          </ol>
        </AccordionDetails>
      </AccordionStyled>

    </FAQContainer>
  </FAQDrawer>
)

const atm = [
  `Masukkan Kartu Anda.`,
  `Pilih Bahasa.`,
  `Masukkan PIN ATM Anda.`,
  `Pilih "Menu Lainnya".`,
  `Pilih "Transfer".`,
  `Pilih Jenis rekening yang akan Anda gunakan (Contoh; "Dari Rekening Tabungan").`,
  `Pilih "Virtual Account Billing".`,
  `Masukkan nomor Virtual Account Anda (contoh: 8241002201150001).`,
  `Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.`,
  `Konfirmasi, apabila telah sesuai, lanjutkan transaksi.`,
  `Transaksi Anda telah selesai.`
]

const bniMobile = [
  `Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password.`,
  `Pilih menu "Transfer".`,
  `Pilih menu "Virtual Account Billing" kemudian pilih rekening debet.`,
  `Masukkan nomor Virtual Account Anda (contoh: 8241002201150001) pada menu "input baru".`,
  `Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi`,
  `Konfirmasi transaksi dan masukkan Password Transaksi.`,
  `Pembayaran Anda Telah Berhasil.`
]
