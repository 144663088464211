import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import Footer from '../../../CommonComponents/Footer/Footer'
import { priceFormatter } from '../../../common/Utils'

const Container = styled('div')({
    height: 'calc(100vh - 84px)',
    padding: '0 1rem',
    position: 'relative',
    overflow: 'hidden',
    overflowY: 'auto'
})

const PaymentSuccessfulContainer = styled('div')({
    textAlign: 'center',
    marginTop: '6rem',
    '& > img': {
        marginBottom: '1.5rem'
    },
    '& > p': {
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '2rem',
        marginBottom: '0.5rem',
    },
    '& > span': {
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '1.25rem',
    }
})

const TransactionDetails = styled('div')(() => {
    const textStyle = {
      fontSize: '0.875rem',
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: '1.25rem',
    }
    return {
      border: '1px solid #DFE2E6',
      borderRadius: '0.75rem',
      padding: '1rem',
      margin: '1.5rem 0',
      '& > p': {
        ...textStyle,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        marginBottom: '1rem'
      }
    }
})

const TextContainer = styled('div')(({totalprice}) => {
    const textStyle = {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
      marginBottom: '0.5rem'
    }
    if(totalprice === 'ID Pesanan') textStyle.marginBottom = 0;
    if(totalprice === 'Total Jumlah') {
      textStyle.fontSize = '1rem'
      textStyle.fontWeight = '700'
      textStyle.lineHeight = '1.5rem'
      textStyle.marginBottom = 0
    }
    return {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& p': textStyle
    }
})

const PaymentSuccess = () => {
    const { bankName, totalamount, bookingId } = useParams()
    const bankDetails = {
        'Metode Pembayaran': 'Virtual Account',
        'Nama Bank': bankName,
        'ID Pesanan': `#${bookingId}`,
        'Total Jumlah': `Rp ${priceFormatter(parseInt(totalamount))}`,
    }
    return (
        <Container>
            <PaymentSuccessfulContainer>
                <img src={'/images/successChecked.svg'} alt="payment successfull" />
                <Typography>Booking Berhasil</Typography>
                <span>Selamat! Tiket anda berhasil dipesan</span>
            </PaymentSuccessfulContainer>

            <TransactionDetails>
                <Typography>Detail Transaksi</Typography>
                {Object.keys(bankDetails).map((bankDetailsKey, i) => 
                    <Fragment key={i}> 
                        <TextContainer totalprice={bankDetailsKey}>
                            <Typography id={bankDetailsKey} children={bankDetailsKey} color={bankDetailsKey === 'Total Jumlah' ? '#333' : '#555'} />
                            <Typography id={bankDetailsKey+'_value'} children={bankDetails[bankDetailsKey]} color={bankDetailsKey === 'Total Jumlah' ? '#167F50' : '#333'} />
                        </TextContainer>
                        {i===2 && <Divider sx={{margin: '1rem 0'}} />}
                    </Fragment>
                )}
            </TransactionDetails>

            {/* <CONFIRM_BUTTON
                children={'Konfirmasi'}
                variant={'contained'}
            /> */}

            <Footer type={'poweredByAyoconnectAll'} />
        </Container>
    )
}

export default PaymentSuccess