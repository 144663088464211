import React, { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Divider, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import Footer from '../../../CommonComponents/Footer/Footer'
import { getCustomerContactDetails, priceFormatter } from '../../../common/Utils'
import { confirmPayment } from '../../../api/ApiUtils'
import { PopUpModal } from '../../../CommonComponents/PopUp/PopUpModal'
import Loader from '../../../CommonComponents/Loader/Loader'

const Container = styled('div')({
    height: 'calc(100vh - 84px)',
    padding: '0 1rem',
    position: 'relative',
    overflow: 'hidden',
    overflowY: 'auto'
})
const TitleHeader = styled(Typography)({
    fontSize: '1.25rem',
    fontWeight: '600',
    lineHeight: '1.875rem',
    padding: '1rem 0'
})

const TextContainer = styled('div')(({totalprice}) => {
    const textStyle = {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.25rem',
    }
    if(totalprice === 'Jumlah Total') {
        textStyle.color = '#333'
        textStyle.fontSize = '1rem'
        textStyle.fontWeight = '700'
        textStyle.lineHeight = '1.5rem'
    }
    
    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1rem',
        '& p': textStyle
    }
})

const CONFIRM_BUTTON = styled(Button)({
    background: '#333',
    color: '#FFF',
    position: 'absolute',
    bottom: '1rem',
    padding: '0.75rem 1rem',
    width: 'calc(100% - 2rem)',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '1.5rem',
    textTransform: 'capitalize',
    '&:hover': {
        background: '#333'
    }
})

const PaymentDetails = () => {
    
    const StaticUrl = process.env.REACT_APP_STATIC_URL

    const navigate = useNavigate()
    const { amount, adminFee, bankCode, bookingId, bankName, enrollmentPaymentId } = useParams()
    const bankDetails = {
        'Metode Pembayaran': 'Virtual Account',
        'Nama Bank': bankName,
        'ID Pesanan': `${bookingId}`,
        'Jumlah': `Rp ${priceFormatter(amount)}`,
        'Biaya Admin': `Rp ${priceFormatter(adminFee)}`,
        'Jumlah Total': `Rp ${priceFormatter(parseInt(amount) + parseInt(adminFee))}`,
    }

    const [loading, setLoading] = useState(false);
    const handlePaymentConfirmation = async() => {
        setLoading(true)
        const totalamount = parseInt(amount) + parseInt(adminFee)
        
        const { customerContactDetails } = getCustomerContactDetails()
        const { phoneNo, phoneCode } = customerContactDetails
        const payload = {
            virtualAccountPhone: phoneCode + phoneNo,
            bankCode,
            amount: parseInt(amount),
            adminFee: parseInt(adminFee),
            enrollmentId: parseInt(enrollmentPaymentId),
        }
        const confirmPaymentResponse = await confirmPayment(payload)
        if(confirmPaymentResponse?.success || confirmPaymentResponse?.data?.success) {
            const {virtualAccountNo, correlationId} = confirmPaymentResponse.data.data
            navigate(`/paymentConfirmation/${bookingId}/${totalamount}/${bankName}/${bankCode}/${enrollmentPaymentId}/${virtualAccountNo}/${correlationId}`)
        }
        else {
            setErrorPopUp(true)
        }
        setLoading(false)
    }

    const [errorPopUp, setErrorPopUp] = useState(false);
    const paymentConfirmationFailedMessage = 'Terdapat kendala saat memproses transaksi Anda. Mohon ulangi langkah pemesanan untuk menyelesaikan proses dengan sukses.'
    const handlePopUpClose = () => {
        setErrorPopUp(false)
        window.parent.postMessage('reload', StaticUrl);
    }
    return (
        <Container>
            <Loader loading={loading} />
            <PopUpModal open={errorPopUp} message={paymentConfirmationFailedMessage} error={true} handlePopUpClose={handlePopUpClose} />
            <TitleHeader children={'Rincian Pembayaran'} color={'#333'} />
            {Object.keys(bankDetails).map((bankDetailsKey, i) => 
                <Fragment key={i}> 
                    <TextContainer totalprice={bankDetailsKey}>
                        <Typography id={bankDetailsKey} children={bankDetailsKey} color={'#666'} />
                        <Typography id={bankDetailsKey+'_value'} children={bankDetails[bankDetailsKey]} color={'#333'} />
                    </TextContainer>
                    {i===2 && <Divider sx={{marginBottom: '1rem'}} />}
                </Fragment>
            )}
            <CONFIRM_BUTTON
                id={'confirmPaymentButton'}
                children={'Konfirmasi Pembayaran'}
                variant={'contained'}
                onClick={handlePaymentConfirmation}
            />
            <Footer type={"poweredByAyoconnectAll"} />
        </Container>
    )
}

export default PaymentDetails