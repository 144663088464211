/* eslint-disable */
import React, { useEffect, useState } from "react";

import {
  Accordion,
  Checkbox,
  Grid,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CalendarComponent from "../../CommonComponents/CalendarComponent/CalendarComponent";
import moment from "moment";
import { countryData } from "../../common/Country";
import SelectDropdown from "../../CommonComponents/SelectDropdown/SelectDropdown";
import {
  isDateBeforeSixMonths,
  validateAge,
  INDONESIA_CODE,
  salutationList,
} from "../../common/Utils";
import TextInput from "../../CommonComponents/TextInput/TextInput";

const PassengerInfo = ({
  ticiketInfo: {
    adultPassengers,
    childPassengers,
    totalPassengers,
    infantPassengers,
  },
  submitError,
  initialFormData,
  documentType,
  docRequireForDomestic,
  resetBaggageSelectionData,
}) => {
  const [userID, submitUserID] = useState(""); // set user id on click of submit button in Slider
  const [userIndex, setUserIndex] = useState(""); // gets the user index from list array on click on Jenis ID

  const [list, setList] = useState([]);

  const [titleFocus, setTitleFocus] = useState(false);
  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [citizenshipFocus, setCitizenshipFocus] = useState(false);
  const [nomorIdFocus, setNomorIdFocus] = useState(false);
  const [passportNumFocus, setPassportNumFocus] = useState(false);
  const [passportIssueFocus, setPassportIssueFocus] = useState(false);
  const [passengerDocumentType, setPassengerDocumentType] =
    useState(documentType);

  useEffect(() => {
    if (totalPassengers > 0) {
      let temp = [];
      let j = adultPassengers,
        k = childPassengers,
        z = infantPassengers;

      let KAI = JSON.parse(localStorage.getItem("KAI"));
      if (KAI?.passengerList?.length) {
        temp = [...KAI.passengerList];
        for (const element of temp) {
          element.expand = false;
          element.containerError = false;
          element.ageError = "";
        }
        KAI.passengerList = temp;
        localStorage.setItem("KAI", JSON.stringify(KAI));
      } else {
        for (let i = 0; i < totalPassengers; ) {
          if (j > 0) {
            temp[i] = {
              passenger: "adult",
              salutation: "",
              pFirstName: "",
              lastName: "",
              noLastName: false,
              dateOfBirth: "",
              citizenship: "",
              numberID: "",
              id: i,
              expand: false,
              passportNo: "",
              passportIssuingCountry: "",
              passportExpirationDate: "",
              ageError: "",
              containerError: false,
              passengerDocumentType: documentType,
            };
            j--;
            i++;
          }
          if (j == 0 && k > 0) {
            temp[i] = {
              passenger: "child",
              salutation: "",
              pFirstName: "",
              lastName: "",
              noLastName: false,
              dateOfBirth: "",
              citizenship: "",
              numberID: "",
              id: i,
              expand: false,
              passportNo: "",
              passportIssuingCountry: "",
              passportExpirationDate: "",
              ageError: "",
              containerError: false,
              passengerDocumentType: documentType,
            };
            k--;
            i++;
          }
          if (j == 0 && k == 0 && z > 0) {
            temp[i] = {
              passenger: "infant",
              salutation: "",
              pFirstName: "",
              lastName: "",
              noLastName: false,
              dateOfBirth: "",
              citizenship: "",
              numberID: "",
              id: i,
              expand: false,
              passportNo: "",
              passportIssuingCountry: "",
              passportExpirationDate: "",
              ageError: "",
              containerError: false,
              passengerDocumentType: documentType,
            };
            z--;
            i++;
          }
        }
        if (KAI) {
          KAI.passengerList = [...temp];
        } else {
          KAI = { passengerList: [...temp] };
        }

        localStorage.setItem("KAI", JSON.stringify(KAI));
      }
      setList(temp);
    }
  }, [adultPassengers, childPassengers, totalPassengers, infantPassengers]);

  const [salutation, setSalutation] = useState("");
  const [pFirstName, setFirstName] = useState("");
  const [noLastName, setNoLastName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [citizenship, setCitizenship] = useState("ID");
  const [passengerNumberID, setPassengerNumberID] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [passportIssuingCountry, setPassportIssuingCountry] = useState("");
  const [error, setError] = useState(false);
  const [dateOfBirth, setDateofBirth] = useState(null);
  const [passportExpirationDate, setPassportExpirationDate] = useState("");

  const handleAccordion = (i) => {
    setUserIndex(i);
    let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
    p.then((res) => {
      let KAI = res;
      if (KAI.passengerList.length === 0) {
        let temp = [];
        let j = adultPassengers,
          k = childPassengers,
          z = infantPassengers;

        let KAI = JSON.parse(localStorage.getItem("KAI"));
        if (KAI?.passengerList?.length) {
          temp = [...KAI.passengerList];
          for (const element of temp) {
            element.expand = false;
            element.ageError = "";
          }
          KAI.passengerList = temp;
          localStorage.setItem("KAI", JSON.stringify(KAI));
        } else {
          for (let i = 0; i < totalPassengers; ) {
            if (j > 0) {
              temp[i] = {
                passenger: "adult",
                salutation: "",
                pFirstName: "",
                lastName: "",
                noLastName: false,
                dateOfBirth: "",
                citizenship: "",
                numberID: "",
                id: i,
                expand: false,
                passportNo: "",
                passportIssuingCountry: "",
                passportExpirationDate: "",
                ageError: "",
                containerError: false,
                passengerDocumentType: documentType,
              };
              j--;
              i++;
            }
            if (j == 0 && k > 0) {
              temp[i] = {
                passenger: "child",
                salutation: "",
                pFirstName: "",
                lastName: "",
                noLastName: false,
                dateOfBirth: "",
                citizenship: "",
                numberID: "",
                id: i,
                expand: false,
                passportNo: "",
                passportIssuingCountry: "",
                passportExpirationDate: "",
                ageError: "",
                containerError: false,
                passengerDocumentType: documentType,
              };
              k--;
              i++;
            }
            if (j == 0 && k == 0 && z > 0) {
              temp[i] = {
                passenger: "infant",
                salutation: "",
                pFirstName: "",
                lastName: "",
                noLastName: false,
                dateOfBirth: "",
                citizenship: "",
                numberID: "",
                id: i,
                expand: false,
                passportNo: "",
                passportIssuingCountry: "",
                passportExpirationDate: "",
                ageError: "",
                containerError: false,
                passengerDocumentType: documentType,
              };
              z--;
              i++;
            }
          }
          if (KAI) {
            KAI.passengerList = [...temp];
          } else {
            KAI = { passengerList: [...temp] };
          }

          localStorage.setItem("KAI", JSON.stringify(KAI));
        }
        setList(temp);
      }
      let list = [...KAI.passengerList];
      for (let index = 0; index < list.length; index++) {
        if (index == i) {
          list[index].expand = !list[index].expand;
          setSalutation(list[i].salutation);
          setFirstName(list[i].pFirstName);
          setNoLastName(list[i].noLastName);
          if (moment(list[i].dateOfBirth).isValid()) {
            setDateofBirth(list[i].dateOfBirth);
          } else {
            setDateofBirth(null);
          }
          setCitizenship(list[i].citizenship || "ID");
          setLastName(list[i]?.lastName);
          setPassengerNumberID(list[i]?.numberID);
          setPassportNo(list[i]?.passportNo);
          setPassportIssuingCountry(list[i]?.passportIssuingCountry);
          setPassengerDocumentType(
            list[i]?.passengerDocumentType || documentType
          );
          if (moment(list[i].passportExpirationDate).isValid()) {
            setPassportExpirationDate(list[i].passportExpirationDate);
          } else {
            setPassportExpirationDate("");
          }
          if (list[index].containerError) {
            setError(true);
          } else {
            setError(false);
          }
        } else list[index].expand = false;
      }
      // setError(false)
      KAI.passengerList = [...list];
      localStorage.setItem("KAI", JSON.stringify(KAI));
      setList(list);
    });
  };

  const validateNumberId = (p) => {
    let isValidated = true;
    if (passengerDocumentType === "NODOC") {
      return true;
    }
    if (p) {
      if (passengerDocumentType === "NATIONAL_ID") {
        isValidated = p.numberID !== "" && p.numberID.length == 16;
      } else if (passengerDocumentType === "PASSPORT") {
        isValidated =
          p.passportNo !== "" &&
          p.passportNo.length == 10 &&
          p.passportIssuingCountry !== "" &&
          p.passportExpirationDate !== "" &&
          !isDateBeforeSixMonths(
            passportExpirationDate,
            initialFormData.depDate
          );
      }
    } else {
      if (passengerDocumentType === "NATIONAL_ID") {
        isValidated =
          passengerNumberID !== "" && passengerNumberID.length == 16;
      } else if (passengerDocumentType === "PASSPORT") {
        isValidated =
          passportNo !== "" &&
          passportNo.length >= 7 &&
          passportNo.length <= 10 &&
          passportIssuingCountry !== "" &&
          passportExpirationDate !== "" &&
          !isDateBeforeSixMonths(
            passportExpirationDate,
            initialFormData.depDate
          );
      }
    }

    return isValidated;
  };

  const checkAgeValidation = (dateOfBirth) => {
    let ageError = validateAge(list, userIndex, initialFormData, dateOfBirth);
    let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
    p.then((res) => {
      let KAI = res;
      let list = [...KAI.passengerList];
      list[userIndex].ageError = ageError;
      setList(list);
      KAI.passengerList = [...list];
      localStorage.setItem("KAI", JSON.stringify(KAI));
    });
  };
  useEffect(() => {
    if (dateOfBirth) {
      checkAgeValidation(dateOfBirth);
    }
  }, [dateOfBirth]);

  // after clicking Submit button.
  const handleSubmitPassengerInfo = (passenger) => {
    let expand;
    let containerError;
    let ageError = validateAge(list, userIndex, initialFormData, dateOfBirth);
    if (
      pFirstName.length > 1 &&
      (lastName.length > 1 || noLastName) &&
      salutation !== "" &&
      dateOfBirth !== "" &&
      dateOfBirth !== null &&
      citizenship !== "" &&
      validateNumberId() &&
      !ageError
    ) {
      setError(false);
      expand = false;
      containerError = false;
    } else {
      setError(true);
      expand = true;
    }

    let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
    p.then((res) => {
      let KAI = res;
      let list = [...KAI.passengerList];
      list[userIndex].expand = expand;
      list[userIndex].containerError = containerError;
      list[userIndex].salutation = salutation;
      list[userIndex].pFirstName = pFirstName;
      list[userIndex].lastName = lastName;
      list[userIndex].noLastName = noLastName;
      list[userIndex].dateOfBirth = dateOfBirth;
      list[userIndex].citizenship = citizenship;
      list[userIndex].numberID = passengerNumberID;
      list[userIndex].passportNo = passportNo;
      list[userIndex].passportIssuingCountry = passportIssuingCountry;
      list[userIndex].passportExpirationDate = passportExpirationDate;
      list[userIndex].ageError = ageError;
      list[userIndex].passengerDocumentType = passengerDocumentType;
      setList(list);
      KAI.passengerList = [...list];
      localStorage.setItem("KAI", JSON.stringify(KAI));
      resetBaggageSelectionData();
    });
  };

  useEffect(() => {
    if (submitError) {
      let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
      p.then((res) => {
        let KAI = res;

        if (KAI?.passengerList?.length) {
          let passengerList = KAI.passengerList;
          let expandFlag = false;
          for (let i = 0; i < passengerList.length; i++) {
            if (
              passengerList[i].salutation === "" ||
              passengerList[i].pFirstName.length < 2 ||
              (passengerList[i].lastName.length < 2 &&
                !passengerList[i].noLastName) ||
              passengerList[i].dateOfBirth === "" ||
              passengerList[i].dateOfBirth === null ||
              passengerList[i].citizenship === "" ||
              !validateNumberId(passengerList[i])
            ) {
              passengerList[i].expand = !expandFlag ? true : false;
              expandFlag = true;
              passengerList[i].containerError = true;
              setError(true);
              setPassengerNumberID(passengerList[i].numberID);
              setSalutation(passengerList[i].salutation);
              setFirstName(passengerList[i].pFirstName);
              setLastName(passengerList[i].lastName);
              setNoLastName(passengerList[i].noLastName);
              setPassengerDocumentType(
                passengerList[i]?.passengerDocumentType || documentType
              );
              if (moment(list[i].dateOfBirth).isValid()) {
                setDateofBirth(passengerList[i].dateOfBirth);
              } else {
                setDateofBirth(null);
              }
              if (moment(list[i].passportExpirationDate).isValid()) {
                setPassportExpirationDate(
                  passengerList[i].passportExpirationDate
                );
              } else {
                setPassportExpirationDate("");
              }
              setCitizenship(passengerList[i].citizenship || "ID");
              setUserIndex(i);
            } else {
              passengerList[i].expand = false;
            }
          }
          setList(passengerList);
          KAI.passengerList = [...passengerList];
          localStorage.setItem("KAI", JSON.stringify(KAI));
        }
      });
    }
  }, [submitError]);

  useEffect(() => {
    if (userID && list.length) {
      let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
      p.then((res) => {
        let KAI = res;
        KAI.passengerList[userIndex].numberID = "";
        setList(KAI.passengerList);
        localStorage.setItem("KAI", JSON.stringify(KAI));
        submitUserID("");
        setPassengerNumberID("");
      });
    }
  }, [userID]);
  const getPassportDateError = () => {
    if (
      (passportExpirationDate === "" || passportExpirationDate === null) &&
      error
    ) {
      return "Tanggal Habis Berlaku tidak boleh kosong";
    }

    if (
      isDateBeforeSixMonths(passportExpirationDate, initialFormData.depDate)
    ) {
      return "Masa berlaku passport tidak boleh kurang dari 6 bulan";
    }
    return "";
  };

  const onChangeNationality = (e) => {
    if (
      initialFormData.arrivalAirportDetails.countryCode ===
      initialFormData.departureAirportDetails.countryCode
    ) {
      if (e.target.value !== INDONESIA_CODE) {
        if (docRequireForDomestic) {
          setPassengerDocumentType("PASSPORT");
        } else {
          setPassengerDocumentType("NODOC");
        }
      } else {
        setPassengerDocumentType(documentType);
      }
    }
    setCitizenship(e.target.value);
  };

  const getAccordionLabel = (
    passenger,
    adultPassengers,
    childPassengers,
    index
  ) => {
    return passenger === "child"
      ? `Anak ${index + 1 - adultPassengers}`
      : `Bayi ${index + 1 - (adultPassengers + childPassengers)}`;
  };

  const getDocTypeLabel = (currentPaxDoc, submitedPassportNumber) => {
    return currentPaxDoc == "PASSPORT"
      ? "Passport No.: " + submitedPassportNumber
      : "";
  };

  const getPassengerNumberIdError = (passengerNumberID) => {
    return passengerNumberID.length < 16
      ? "Nomor Identitas harus 16 digit"
      : "";
  };

  const getPassengerNoError = (passportNo) => {
    return passportNo.length < 7
      ? "Nomor passport harus 7 sampai 10 digit."
      : "";
  };

  return (
    <div id="slidercomponent">
      <div style={{ marginTop: "24px" }}>
        <Typography
          children={"Data Penumpang"}
          className={"inputSectionLabels"}
        />
      </div>
      {/* passenger => (adult/child) | passengerID => (KTP/Paspor) */}
      {list.map(
        (
          {
            passenger,
            passengerID,
            numberID,
            id,
            ageError: passengerAgeError,
            expand,
            passportNo: submitedPassportNumber,
            lastName: submitedLastName,
            pFirstName: submitedFirstName,
            passengerDocumentType: currentPaxDoc,
            containerError,
          },
          i
        ) => {
          return (
            <div key={i} style={{ marginTop: "16px" }}>
              <Accordion
                id={`${passenger}-passengerInfo-${i}`}
                expanded={expand}
                onChange={() => handleAccordion(i)}
                className={
                  containerError ? "dropShadowError" : "dropShadow"
                } /*style={{background: openUserID ? "transparent" : ""}}*/
              >
                <AccordionSummary>
                  {(expand && userIndex == i) ||
                  name == "" ||
                  numberID == "" ? (
                    // <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
                    <Grid container>
                      <Grid xs={9} item>
                        <Grid container>
                          {(expand || submitedFirstName === "") && (
                            <>
                              <Grid item>
                                {" "}
                                <AccountCircleIcon
                                  style={{
                                    marginRight: "10px",
                                    color: "#1A1A1A",
                                  }}
                                />{" "}
                              </Grid>
                              <Grid
                                item
                                style={{ position: "relative", top: "3px" }}
                              >
                                {containerError && !expand ? (
                                  <Typography
                                    className="inputSecondarySectionLabelsError"
                                    children={"Isi Data Penumpang"}
                                  />
                                ) : (
                                  <Typography
                                    children={
                                      passenger === "adult"
                                        ? `Dewasa ${i + 1}`
                                        : getAccordionLabel(
                                            passenger,
                                            adultPassengers,
                                            childPassengers,
                                            i
                                          )
                                    }
                                    className={
                                      containerError
                                        ? "inputSecondarySectionLabelsError"
                                        : "inputSecondarySectionLabels"
                                    }
                                  />
                                )}
                              </Grid>
                              <Grid item>
                                {" "}
                                <span
                                  style={{ color: "red", fontSize: "bold" }}
                                >
                                  *
                                </span>{" "}
                              </Grid>
                            </>
                          )}
                          {!expand && submitedFirstName !== "" && (
                            <>
                              <Grid item>
                                <Grid container>
                                  <Typography
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "12px",
                                      color: "#1A1A1A",
                                    }}
                                    children={
                                      submitedLastName
                                        ? submitedFirstName +
                                          " " +
                                          submitedLastName
                                        : submitedFirstName
                                    }
                                  />
                                </Grid>
                                {documentType !== "" && (
                                  <Grid container>
                                    <Typography
                                      children={
                                        currentPaxDoc == "NATIONAL_ID"
                                          ? "KTP: " + numberID
                                          : getDocTypeLabel(
                                              currentPaxDoc,
                                              submitedPassportNumber
                                            )
                                      }
                                      style={{
                                        fontSize: "12px",
                                        color: "#666",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      {expand && userIndex == i ? (
                        <Grid
                          item
                          style={{ left: "22%", position: "relative" }}
                        >
                          {" "}
                          <KeyboardArrowUpRoundedIcon
                            style={{ fontSize: "16px" }}
                          />{" "}
                        </Grid>
                      ) : (
                        <Grid item style={{ left: "5%", position: "relative" }}>
                          {submitedFirstName !== "" ? (
                            <Grid container>
                              <Grid
                                item
                                style={{
                                  position: "relative",
                                  top: "3px",
                                  marginRight: "3px",
                                }}
                              >
                                <EditIcon
                                  style={{
                                    color: "#1A1A1A",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography
                                  children={"Ubah"}
                                  style={{
                                    fontSize: "14px",
                                    color: "#1A1A1A",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container>
                              <Grid
                                item
                                style={{
                                  position: "relative",
                                  top: "3px",
                                  marginRight: "3px",
                                }}
                              >
                                <AddIcon
                                  style={{
                                    color: "#1A1A1A",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography
                                  children={"Isi Data"}
                                  style={{
                                    fontSize: "14px",
                                    color: "#1A1A1A",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>
                        <Typography
                          children={pFirstName}
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "#1A1A1A",
                            textTransform: "capitalize",
                          }}
                        />
                        <Typography
                          children={`${passengerID} - ${numberID}`}
                          style={{ fontSize: "12px", color: "#666" }}
                        />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <EditRoundedIcon
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                        <Typography
                          children={"Ubah"}
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#1A1A1A",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: "0" }}>
                  <Divider style={{ marginBottom: "16px" }} />
                  <div>
                    <SelectDropdown
                      error={error && (salutation === "" || !salutation)}
                      value={salutation}
                      label="Sapaan"
                      isFocused={titleFocus}
                      onChange={(e) => {
                        setSalutation(e.target.value);
                      }}
                      onFocus={() => setTitleFocus(true)}
                      onBlur={() => setTitleFocus(false)}
                      errorMessage={
                        (salutation === "" || !salutation) && error
                          ? "Sapaan tidak boleh kosong"
                          : ""
                      }
                      menuItems={salutationList}
                    />
                    <TextInput
                      label="Nama Depan & Tengah (jika ada)"
                      value={pFirstName}
                      onFocus={() => setFirstNameFocus(true)}
                      onBlur={() => setFirstNameFocus(false)}
                      error={error && pFirstName.length < 2}
                      isFocused={firstNameFocus}
                      errorMessage={
                        pFirstName.length < 2 && error
                          ? "Nama depan tidak boleh kosong"
                          : ""
                      }
                      onChange={(e) => {
                        if (
                          e.target.value.length <= 20 &&
                          /^[a-zA-Z ]*$/.test(e.target.value)
                        )
                          setFirstName(e.target.value);
                      }}
                    />

                    <Typography
                      children={"tanpa gelar dan tanda baca"}
                      style={{
                        fontSize: "11px",
                        color: "#979797",
                        marginLeft: "5px",
                      }}
                    />

                    <div style={{ right: "10px", position: "relative" }}>
                      <Grid container>
                        <Grid item>
                          <Checkbox
                            checked={noLastName}
                            sx={{
                              color: "#1a1a1a",
                              "&.Mui-checked": {
                                color: "#1a1a1a",
                              },
                            }}
                            onClick={(e) => {
                              setNoLastName(e.target.checked);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            children={"Tidak punya nama belakang"}
                            style={{ fontSize: "12px", marginTop: "12px" }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    {!noLastName && (
                      <>
                        <TextInput
                          label="Nama Belakang"
                          value={lastName}
                          isFocused={lastNameFocus}
                          error={error && lastName.length < 2}
                          onFocus={() => setLastNameFocus(true)}
                          onBlur={() => setLastNameFocus(false)}
                          errorMessage={
                            lastName.length < 2 && error
                              ? "Nama belakang tidak boleh kosong"
                              : ""
                          }
                          onChange={(e) => {
                            if (passenger === "infant") {
                              if (
                                e.target.value.length <= 10 &&
                                /^[a-zA-Z ]*$/.test(e.target.value)
                              ) {
                                setLastName(e.target.value);
                              }
                            } else if (e.target.value.length <= 20 &&
                              /^[a-zA-Z ]*$/.test(e.target.value)) {
                              setLastName(e.target.value);
                            }
                          }}
                        />
                        <Typography
                          children={"tanpa gelar dan tanda baca"}
                          style={{
                            fontSize: "11px",
                            color: "#979797",
                            marginLeft: "5px",
                          }}
                        />
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        marginBottom: "10px",
                        marginTop: "-14px",
                      }}
                    >
                      <CalendarComponent
                        label={"Tanggal Lahir"}
                        disableFuture={true}
                        inputDate={dateOfBirth || ""}
                        setDate={setDateofBirth}
                        error={
                          (error && !dateOfBirth) || passengerAgeError
                            ? true
                            : false
                        }
                        errorMessage={
                          (error && !dateOfBirth) || passengerAgeError
                            ? passengerAgeError ||
                              "Tanggal lahir tidak boleh kosong"
                            : ""
                        }
                      />
                    </div>
                    <SelectDropdown
                      error={error && citizenship === ""}
                      value={citizenship}
                      label="Kewarganegaraan"
                      isFocused={citizenshipFocus}
                      onChange={onChangeNationality}
                      onFocus={() => setCitizenshipFocus(true)}
                      onBlur={() => setCitizenshipFocus(false)}
                      errorMessage={
                        citizenship === "" && error
                          ? "Kewarganegaraan tidak boleh kosong"
                          : ""
                      }
                      menuItems={countryData}
                    />
                  </div>

                  {passengerDocumentType === "NATIONAL_ID" && (
                    <div style={{ marginTop: "10px" }}>
                      <TextInput
                        label="Nomor ID"
                        onFocus={() => setNomorIdFocus(true)}
                        onBlur={() => setNomorIdFocus(false)}
                        onChange={(e) => {
                          if (e.target.value.length <= 16) {
                            setPassengerNumberID(e.target.value);
                          }
                        }}
                        value={passengerNumberID}
                        isFocused={nomorIdFocus}
                        error={
                          error &&
                          (passengerNumberID === "" ||
                            passengerNumberID.length < 16)
                        }
                        errorMessage={
                          error && passengerNumberID === ""
                            ? "Nomor ID tidak boleh kosong."
                            : getPassengerNumberIdError(passengerNumberID)
                        }
                      />
                    </div>
                  )}

                  {passengerDocumentType === "PASSPORT" && (
                    <div style={{ marginTop: "10px" }}>
                      <TextInput
                        value={passportNo}
                        label="Nomor Paspor"
                        onFocus={() => setPassportNumFocus(true)}
                        onBlur={() => setPassportNumFocus(false)}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setPassportNo(e.target.value);
                          }
                        }}
                        isFocused={passportNumFocus}
                        error={
                          error && (passportNo === "" || passportNo.length < 7)
                        }
                        errorMessage={
                          error && passportNo === ""
                            ? "Nomor Paspor tidak boleh kosong."
                            : getPassengerNoError(passportNo)
                        }
                      />
                    </div>
                  )}
                  {passengerDocumentType === "PASSPORT" && (
                    <div style={{ marginTop: "10px" }}>
                      <SelectDropdown
                        error={error && passportIssuingCountry === ""}
                        value={passportIssuingCountry}
                        label="Negara Penerbit"
                        isFocused={passportIssueFocus}
                        onChange={(e) => {
                          if (
                            e.target.value.length <= 25 &&
                            /^[a-zA-Z ]*$/.test(e.target.value)
                          )
                            setPassportIssuingCountry(e.target.value);
                        }}
                        onFocus={() => setPassportIssueFocus(true)}
                        onBlur={() => setPassportIssueFocus(false)}
                        errorMessage={
                          passportIssuingCountry === "" && error
                            ? "Negara Penerbit boleh kosong"
                            : ""
                        }
                        menuItems={countryData}
                      />
                    </div>
                  )}
                  {passengerDocumentType === "PASSPORT" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: "1",
                          marginTop: "-14px",
                        }}
                      >
                        <CalendarComponent
                          label={"Tanggal Habis Berlaku"}
                          disablePast={true}
                          inputDate={passportExpirationDate || ""}
                          setDate={setPassportExpirationDate}
                          error={getPassportDateError() ? true : false}
                          errorMessage={
                            getPassportDateError()
                              ? "Masa berlaku passport tidak boleh kurang dari 6 bulan"
                              : ""
                          }
                        />
                      </div>
                    </>
                  )}

                  <Button
                    id={`simpanPassenger-${i}`}
                    fullWidth
                    children={"Simpan"}
                    className={"submitButton"}
                    onClick={() => handleSubmitPassengerInfo()}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        }
      )}
    </div>
  );
};

export default PassengerInfo;
