/* eslint-disable */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";

const Dialog_VA = styled(Dialog)({
  '.MuiDialog-paper': {
    padding: '24px',
    borderRadius: '24px',
    textAlign: 'center',
  },
  '.MuiDialogTitle-root': {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '32px',
    color: '#1A1A1A',
    marginBottom: '8px',
    marginTop: '16px',
    padding: 0
  },
  '.MuiDialogContent-root': {
    padding: 0
  },
  '.MuiDialogContentText-root': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#1A1A1A',
    marginBottom: '24px'
  },
  '.MuiDialogActions-root': {
    padding: 0
  }
})

const VAActionButton = styled(Button)(({type}) => {
  const buttonStyle = {
    padding: '12px 8px',
    borderRadius: '8px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    textTransform: 'capitalize'
  }
  if(type === 'Pesan Ulang') {
    return {
      backgroundColor: '#CB2029',
      color: '#FFF',
      ...buttonStyle,
      '&:hover': {
        backgroundColor: '#CB2029',
        color: '#FFF',
      }
    }
  }
  else return {}
})

export const PopUpModal = ({open, message, error, handlePopUpClose, type, VAPopupProps}) => {

  if(type === 'VAPopup') {
    const { imgURL, title, message, action } = VAPopupProps;
    return (
      <Dialog_VA
        open={open}
        onClose={handlePopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'xs'}
      >
        <img src={imgURL} alt="timeout image" height={96} />
        <DialogTitle id="alert-dialog-title" children={title} />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || "Something went wrong! Please try again."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VAActionButton 
            children={action}
            onClick={handlePopUpClose} 
            type={action}
            fullWidth 
            variant="contained"
          />
        </DialogActions>
      </Dialog_VA>
    );
  }
  
  else {
    return (
      <Dialog
        open={open}
        onClose={handlePopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">{error ?  error : "OOPS!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || "Something went wrong! Please try again."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopUpClose} autoFocus className={"submitBlackButtonPopUp"} >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
