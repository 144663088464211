import React, { Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import Footer from '../../CommonComponents/Footer/Footer';
import { priceFormatter } from '../../common/Utils';

const Container = styled('div')({
    height: 'calc(100vh - 84px)',
    padding: '0 1rem',
    position: 'relative',
    overflow: 'hidden',
    overflowY: 'auto'
})
const TitleHeader = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: '600',
    lineHeight: '2rem',
    padding: '1rem 0'
})

const LIST_STYLED = styled(List)({
    '& .MuiListItem-root': {
        borderRadius: '0.5rem',
        marginBottom: '1rem',
        '& .MuiAvatar-root': {
            outline: '1px solid #DDD',
            background: 'transparent'
        },
        '& .MuiListItemText-root': {
            marginRight: '40px',
            '& .MuiListItemText-primary': {
                color: '#333',
                fontSize: '1rem',
                fontWeight: '500',
                lineHeight: '1.5rem',
            },
            '& .MuiListItemText-secondary': {
                '& span': {
                    color: '#666',
                    fontSize: '0.75rem',
                    fontWeight: '400',
                    lineHeight: '1.125rem',
                    margin: '0.38rem 0 0 0',
                    '& span': {
                        fontWeight: '600'
                    }
                }
            }
        },
    },   
})
const ListItemStyled = styled(ListItem)(({setdisabled}) => {
    if(setdisabled === 'disabled') {
        return {
            background: '#dddddd4a',
            paddingLeft: '0.5rem',
            '& .MuiListItemText-root': {
                display: 'flex',
                flexFlow: 'column-reverse',
                '& .MuiListItemText-secondary': {
                    color: '#ffa500',
                }
            },
        }
    }
    else {
        return {}
    }
})

const SelectPaymentMethod = () => {
    const { amount, bookingId, enrollmentPaymentId } = useParams()
    const navigate = useNavigate()
    const handlePaymentBank = ({bankCode, bankName, adminFee, logo, commingSoon}) => {
        if(commingSoon) return;
        localStorage.setItem('vaLogo', logo || '')
        navigate(`/paymentDetails/${bookingId}/${amount}/${adminFee}/${bankName}/${bankCode}/${enrollmentPaymentId}`)
    }

    const bankList = [
        {bankCode: '009', bankName: 'BNI', virtualAccount: 'BNI Virtual Account', adminFee: '3500', commingSoon: false, logo: '/images/bankBNI.svg'},
        {bankName: 'Mandiri', virtualAccount: 'Mandiri Virtual Account', adminFee: '2500', commingSoon: true, logo: '/images/bankMandiri.svg'},
        {bankName: 'BCA', virtualAccount: 'BCA Virtual Account', adminFee: '2500', commingSoon: true, logo: '/images/bankBCA.svg'},
        {bankName: 'BRI', virtualAccount: 'BRIVA', adminFee: '2500', commingSoon: true, logo: '/images/bankBRI.svg'},
        // {bankName: 'CIMB', virtualAccount: 'CIMB Virtual Account', adminFee: '2500', logo: '/images/bankCIMB.svg'},
    ]
    if(bookingId) return (
        <Container>
            <TitleHeader children={'Pilih Bank'} color={'#333'} />
            <LIST_STYLED>
                {
                    bankList.map((bank, i) => 
                        <ListItemStyled
                            id={bank.virtualAccount}
                            secondaryAction={
                                <IconButton 
                                id={'select_'+bank.virtualAccount}
                                    children={<NavigateNextIcon htmlColor={'#999'} />} 
                                    disabled={bank?.commingSoon} 
                                />
                            }
                            key={i}
                            onClick={()=>handlePaymentBank(bank)} 
                            setdisabled={bank?.commingSoon ? 'disabled' : ''}
                            disablePadding
                        >
                            <ListItemAvatar>
                                <Avatar 
                                    children={
                                        (bank?.logo && <img alt={bank.name} src={bank?.logo} />) || 
                                        <AccountBalanceSharpIcon htmlColor='#333' />
                                    } 
                                />
                            </ListItemAvatar>
                            <ListItemText 
                                primaryTypographyProps={{
                                    id: bank.bankName
                                }}
                                primary={bank.virtualAccount}
                                secondary={
                                    (bank?.commingSoon && 'Akan Hadir') || 
                                    <span>Biaya Admin: <span id={bank.bankName+'_adminfee'}>Rp {priceFormatter(bank?.adminFee ) || '00'}</span> </span>
                                }
                            />
                        </ListItemStyled>
                    )
                }
            </LIST_STYLED>
            <Footer type={"poweredByAyoconnectAll"} />
        </Container>
    )

    return <Fragment />
}

export default SelectPaymentMethod