/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from "../index";

import { Divider, Paper, Typography } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { convertArrayToObjectByKey, FLIGHT_TYPES, getDateInFormat, getDurationLabel, isEmptyObj, priceFormatter } from '../../common/Utils';
import SortPopUP from './SortPopUp';
import { getAirlines, getSchedule } from '../../api/ApiUtils';
import useLocalstorage, { APP_LOCAL_STORAGE_KEYS } from '../../hooks/useLocalstorage';
import { PopUpModal } from '../../CommonComponents/PopUp/PopUpModal';
import Loader from '../../CommonComponents/Loader/Loader';
import Polling from '../../common/Polling';
import { JOURNEY_TYPE } from '../StationSelection/StationSelection';

const sortPopupOptions = [
    {
        label:"Harga terendah",
        value:"Harga terendah",
        sortFn: (a,b) => parseInt(a.fare) - parseInt(b.fare)
    },
    {
        label:"Waktu berangkat paling awal",
        value:"Waktu berangkat paling awal",
        sortFn: (a,b) => a.departureTime.localeCompare(b.departureTime)
    },
    {
        label:"Waktu berangkat paling akhir",
        value:"Waktu berangkat paling akhir",
        sortFn: (a,b) => b.departureTime.localeCompare(a.departureTime)
    },
    {
        label:"Waktu tiba paling awal",
        value:"Waktu tiba paling awal",
        sortFn: (a,b) => a.arrivalTime.localeCompare(b.arrivalTime)
    },
    {
        label:"Waktu tiba paling akhir",
        value:"Waktu tiba paling akhir",
        sortFn: (a,b) => b.arrivalTime.localeCompare(a.arrivalTime)
    },
    {
        label:"Durasi tersingkat",
        value:"Durasi tersingkat",
        sortFn: (a,b) => parseInt(a.journeyDuration) - parseInt(b.journeyDuration)
    },
];

const NUMBER_OF_RETRIES = 10;
const DELAY_BETWEEN_CALLS_MS = 2000;

const SelectStationPriceCard = () => {
    let detailsFlag = false;
    const { poll } = Polling(DELAY_BETWEEN_CALLS_MS, NUMBER_OF_RETRIES);

    const header = "Cari Tiket Pesawat";
    const navigate = useNavigate();
    const [openSortOptions, setOpenSortOptions] = useState(false);
    const [sortValue, setSortValue] = useState("Harga terendah");
    const [flightDetails, setFlightDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchingAirline, setFetchingAirline] = useState(false);
    const [airlines, setAirlines] = useState({});
    const [flightSearchResults, setFlightSearchResults] = useState([]);
    const [errorPopUp, showErrorPopUp] = useState({showPopUp: false});
    const [roundTripScreen, setRoundTripScreen] = useState(false);

    const { get: getLocalStorageValue, set:setLocalStorageValue } = useLocalstorage();
    const flightType = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing);

    const initialFormData = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.initialFormData);

    const isRoundTrip = initialFormData.journeyType === JOURNEY_TYPE.RoundTrip;
    const resKey = initialFormData.journeyType === FLIGHT_TYPES.roundTrip ? "basicRoundTripFlightSearchResults" : "oneWayFlightSearchResults";

    const handlePopUpClose = () => {
        showErrorPopUp({showPopUp: false});
        navigate(`/`);
    }

    useEffect(() => {
        const sortItem = sortPopupOptions.find(item => item.value === sortValue);
        setFlightDetails([...flightDetails.sort(sortItem.sortFn)]);
    },[sortValue]);

    useEffect(()=> {
        setLoading(true);
        fetchSchedule();
    }, [roundTripScreen])

    async function fetchAirlines(){
        setFetchingAirline(true);
        try{
            const airlinesList = await getAirlines();
            const airlinesByCode = convertArrayToObjectByKey(airlinesList, 'airlineCode');
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.airlines, airlinesByCode);
            setAirlines(airlinesByCode);
        }catch(err){
            console.log(err);
            showErrorPopUp({showPopUp: true, message: err.message});
        }
        setFetchingAirline(false);
    }

    useEffect(() => {
        fetchAirlines();
        localStorage.removeItem("ADDON");
        localStorage.removeItem("KAI");
        let KAI= {};
        localStorage.setItem("KAI", JSON.stringify(KAI));
    }, []);

    const getScheduleReq = () => {
        if(flightType === FLIGHT_TYPES.roundTrip) {
            return {            
                "journey": {
                    "depAirportOrAreaCode": initialFormData.arrivalAirportDetails.areaCode,
                    "arrAirportOrAreaCode": initialFormData.departureAirportDetails.areaCode,
                    "depDate": getDateInFormat(initialFormData.arrDate ,"MM-DD-YYYY"),
                    "seatClass": initialFormData.seatClass
                },
                "passengers": {
                    "adult": initialFormData.passengers.adult,
                    "child": initialFormData.passengers.child,
                    "infant": initialFormData.passengers.infant
                },
                "journeyType": initialFormData.journeyType
            }
        }
        else {
            return {            
                "journey": {
                    "depAirportOrAreaCode": initialFormData.departureAirportDetails.areaCode,
                    "arrAirportOrAreaCode": initialFormData.arrivalAirportDetails.areaCode,
                    "depDate": getDateInFormat(initialFormData.depDate ,"MM-DD-YYYY"),
                    "seatClass": initialFormData.seatClass
                },
                "passengers": {
                    "adult": initialFormData.passengers.adult,
                    "child": initialFormData.passengers.child,
                    "infant": initialFormData.passengers.infant
                },
                "journeyType": initialFormData.journeyType
            }

        }
    }

    const fetchSchedule = async () => {
        const exitConditionFn = (res) => !res.success || res.data.completed;
        const res = await poll(() => getSchedule(getScheduleReq()), exitConditionFn);

        let flightResults = [];
        if(res.success){
            if(res.data.completed && res.data[resKey].length === 0){
                setLoading(false);
                showErrorPopUp({showPopUp: true, message: "No flights found"});    
            }
            else if(res.data.completed){
                flightResults = res.data[resKey];
                if(initialFormData.journeyType === FLIGHT_TYPES.roundTrip){
                    setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightSearchResultsRound, flightResults);
                }else{
                    setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightSearchResultsOneWay, flightResults);
                }
                setFlightSearchResults(flightResults);
                setLoading(false);
            }else{
                setLoading(false);
                showErrorPopUp({showPopUp: true, message: "Failed to load flights, please try again."});    
            }
        }else{
            showErrorPopUp({showPopUp: true, message: res.message});
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchSchedule();
    },[]);

    useEffect(() => {
        if(isEmptyObj(airlines) || flightSearchResults.length === 0){
            return;
        }
        const flightData = flightSearchResults.map((schedule, index) => {
            const {logoUrl:airlineLogo, airlineName, airlineCode} = airlines[schedule.journeys[0].segments[0].operatingAirline] || {};
            
            const flightId = schedule.flightId;
            const depAirportCode = schedule.departureAirport;
            const arrAirportCode = schedule.arrivalAirport;
            
            const {departureDate, departureTime, departureTerminal} = schedule.journeys[0].departureDetail;
            const {arrivalDate, arrivalTime, arrivalTerminal} = schedule.journeys[0].arrivalDetail;

            const journeyDuration = schedule.journeys[0].journeyDuration;
            const journeyDurationFormatted = getDurationLabel(journeyDuration);
            const transit = schedule.numOfTransits == 0 ? "Langsung" : `${schedule.numOfTransits} Pemberhentian`;
    
            const fare = schedule.journeys[0].fareInfo.partnerFare.adultFare.totalFareWithCurrency.amount;
            const fareFormatted = priceFormatter(fare.split(".")[0]);

            return {
                airlineCode,
                airlineLogo,
                airlineName,
                depAirportCode, departureDate, departureTime, departureTerminal,
                arrAirportCode, arrivalDate, arrivalTime, arrivalTerminal,
                journeyDuration,
                journeyDurationFormatted,
                fare,
                fareFormatted,
                flightId,
                transit
            }
        });
        setFlightDetails([...flightData.sort(sortPopupOptions[0].sortFn)]);
    },[airlines, flightSearchResults]);

    const handleTiciketSelect = (flight) => {
        detailsFlag = true;
        const selectedFlight = flightSearchResults.find(flightItem => flightItem.flightId === flight.flightId);

        if(flightType === FLIGHT_TYPES.roundTrip) {
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsRound, selectedFlight);
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing, FLIGHT_TYPES.roundTrip);
        } else {
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsOneWay, selectedFlight);
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing, FLIGHT_TYPES.oneWay);
        }
        navigate(`/ticketDetails`);
    };

    const handleSubmit = (flight) => {
        if(detailsFlag) {
            return
        }
        const selectedFlight = flightSearchResults.find(flightItem => flightItem.flightId === flight.flightId);

        if(flightType === FLIGHT_TYPES.roundTrip) {
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsRound, selectedFlight);
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing, FLIGHT_TYPES.roundTrip);
        } else {
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsOneWay, selectedFlight);
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing, FLIGHT_TYPES.oneWay);
        }

        if(flightType === FLIGHT_TYPES.oneWay && isRoundTrip){
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing, FLIGHT_TYPES.roundTrip);
            setRoundTripScreen(true);
        }else{
            navigate(`/inputdata`);
        }
    };

    return (
        <div style={{height: flightDetails.length > 3 ? "" : "100vh"}}>
            <Loader loading={loading || fetchingAirline} />
            <PopUpModal open={errorPopUp.showPopUp} error={true} message={errorPopUp.message} handlePopUpClose={handlePopUpClose}/>

            <Header header={header} back={"/"} />
            <div style={{padding:"16px", height: `calc(100vh - 149px)`, overflowY:"auto"}}> {/* 151px => height(Header) + height(Footer) + 16px(Top & Bottom Padding of this div) */}
            {
                flightDetails.map((flight, i) =>
                    {

                    return(<Paper key={i} onClick={()=>{ handleSubmit(flight) }} style={{padding:"16px", borderRadius:"8px", boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)", marginBottom:"16px" }}>
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <div style={{display:"flex"}}>
                                <img  src={flight.airlineLogo} style={{width:'24px',height:'24px',marginRight:'4px',padding:'3px',borderRadius:'50%',border:'1px solid lightgrey', objectFit: "contain"}} />
                                <Typography children={flight.airlineName} style={{color: "#1A1A1A" }} className={"stationCardName"} />
                            </div>
                            <div onClick={()=>{ handleTiciketSelect(flight) }}>
                                <Typography children={"Detail"} style={{color: "#1A1A1A", textAlign: 'right', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div style={{display:"flex", marginTop:"16px", justifyContent: "space-between"}}>
                            <div style={{display:"flex"}}>
                                <div>
                                    <Typography children={flight.departureTime} style={{color: "#555" }} className={"stationTime"} />
                                    <Typography children={flight.depAirportCode} style={{color: "#777" }} className={"stationStatusCode"} />
                                </div>
                                <div style={{ alignItems:"center", padding: "0px 5px 0px 5px"}}>
                                    <Typography children={flight.journeyDurationFormatted} style={{fontSize: "12px", textAlign:"center", color: "#979797"}} />
                                    <Divider style={{margin:""}} />
                                    <Typography children={flight.transit} style={{fontSize: "12px", textAlign:"center", color: "#979797"}} />
                                    
                                </div>
                                <div>
                                    <Typography children={flight.arrivalTime} style={{color: "#555" }} className={"stationTime"} />
                                    <Typography children={flight.arrAirportCode} style={{color: "#777" }} className={"stationStatusCode"} />
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <Typography children={`Rp ${flight.fareFormatted}`} style={{color: "#1A1A1A" }} className={"stationCardPrice"} />
                                <Typography children={`/org`} style={{color: "#615A5A", fontSize: "10px", paddingTop: "5px" }} />
                                
                            </div>
                        </div>
                    </Paper>
                    )}
                )
            }
            </div>
            <div id='footer' className="listingFooter">
                <div className='footerBlockLeft' onClick={()=>{setOpenSortOptions(true)}}>
                    <SortIcon style={{color: '#1A1A1A'}} />
                    <Typography children={'Urutkan'} style={{ fontSize: "14px", marginLeft: "10px", color: "#1A1A1A" }} />
                </div>
                {/* <Divider orientation="vertical" flexItem />
                <div className='footerBlockRight'>
                    <FilterAltOutlinedIcon style={{color: '#007DFE'}}/>
                    <Typography children={'Filter'} style={{marginLeft: "10px", fontSize: "14px", color: "#007DFE" }} />
                </div> */}
            </div>
            <SortPopUP slideOpen={openSortOptions} options={sortPopupOptions} setOpenSortOptions={setOpenSortOptions} setSortValue={setSortValue} sortValue={sortValue} />
        </div>
    )
};

export default SelectStationPriceCard;

