import moment from 'moment';
export const INDONESIA_CODE = "ID";

export const FLIGHT_TYPES = {
    oneWay: "ONE_WAY",
    roundTrip: "ROUND_TRIP",
}
/* eslint-disable */
export const priceFormatter = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const getWagonClass = (code, subclass) => {
    switch (code) {
        case 'EKS':
            return `Eksekutif (${subclass})`;
        case 'EKO':
            return `Ekonomi (${subclass})`;
        case 'BIS':
            return `Bussines (${subclass})`;
        default:
            return '';
    }
}

export const convertArrayToObjectByKey = (array, key) => {
    return array.reduce((obj, item) => {
        obj[item[key]] = item;
        return obj;
    }, {});
};

export const getDateInFormat = (date, format) => {
    return moment(date).format(format);
};

export const isEmptyObj = (obj) => {
    return Object.keys(obj ?? {}).length === 0;
};

export const getValueStringOrEmptyString = (val) => {
    return val ?? "";
    
};

export const getDurationLabel = (duration) => {
    let durationInt = parseInt(duration);
    const hours = Math.floor(durationInt / 60);
    const minutes = durationInt % 60;
    return `${hours} j ${minutes} m`;
};

export const getTotalFare = ({
    adultFare,
    childFare,
    infantFare,
    adultCount,
    childCount,
    infantCount,
}) => {
    return (adultFare * adultCount) + (childFare * childCount) + (infantFare * infantCount);
};

export const parseFloatToDecimals = (value) => {
    return parseFloat(parseFloat(value).toFixed(2));
}

export const isDateAfter = (date1, date2) => {
    return moment(date1).isAfter(date2);
}

export const passengerClassMap = {
    "ECONOMY": 'Ekonomi',
    "BUSINESS": 'Bisnis',
    "PREMIUM_ECONOMY": 'Ekonomi Premium',
    "FIRST": 'First Class'
}

export const getTotalPassengers = (passenger) => (
    passenger.adult + passenger.child + passenger.infant
);
export const getTotalPassengersForFare = (passenger) => (
    passenger.adult
);

export const isEmptyString = (str) => {
    return str === '' || str === null || str === undefined;
}

export const isDocCheckValid = (passengers) => {
    let i = 0;
    for (const item of passengers) {
        const passenger1 = item;
        const name1 = passenger1.pFirstName + passenger1.lastName

        for (let j = i + 1; j < passengers.length; j++) {
            const passenger2 = passengers[j];
            const name2 = passenger2.pFirstName + passenger2.lastName
            if (!isEmptyString(passenger1.passportNo)
                && !isEmptyString(passenger2.passportNo)
                && (passenger1.passportNo === passenger2.passportNo || name1 === name2)) {
                if (passenger1.passportNo === passenger2.passportNo) {
                    return { error: true, message: "Nomor identitas tidak boleh sama" };
                } return { error: true, message: "Nama penumpang tidak boleh sama" };
            }

            if (!isEmptyString(passenger1.numberID)
                && !isEmptyString(passenger2.numberID)
                && (passenger1.numberID === passenger2.numberID || name1 === name2)) {
                if (passenger1.numberID === passenger2.numberID) {
                    return { error: true, message: "Nomor identitas tidak boleh sama" };
                } return { error: true, message: "Nama penumpang tidak boleh sama" };
            }

        }
        i++;
    }
    return { error: false };
}

const isDateBefore = (date, numOfMonths, depDate) => moment(date).isBefore(moment(depDate).add(numOfMonths, 'months').format('YYYY-MM-DD'));

export const isDateBeforeSixMonths = (date, depDate) => {
    return isDateBefore(date, 6, depDate);
}

/**
 * 
 * @param {*} dateOfBirth - format YYYY-MM-DD 
 * @param {*} date  - format YYYY-MM-DD
 * @returns    - age in decimal incase of non-whole nums
 */
export const getAgeAtGivenDate = (dateOfBirth, date) => moment(date).diff(moment(dateOfBirth).format("YYYY-MM-DD"), 'years', true);

export const validateAge = (list, userIndex, initialFormData, dateOfBirth) => {
    const age = getAgeAtGivenDate(dateOfBirth, initialFormData.depDate);
    if (list[userIndex].passenger === "adult") {
        if (age < 12) {
            return (`Umur minimal dari Dewasa harus lebih dari 12 tahun`)
        } else {
            return ("");
        }
    } else if (list[userIndex].passenger === "child") {
        if (age < 2 || age >= 12) {
            return (`Umur dari anak-anak harus 2-12 tahun`)
        } else {
            return ("");
        }
    } else if (list[userIndex].passenger === "infant") {
        if (age >= 2) {
            return (`Umur dari Bayi harus dibawah 2 tahun`)
        } else {
            return ("");
        }
    }
    return "";
}

export const getFareWithoutDecimals = (fare) => {
    return fare.split(".")[0];
}

export const salutationLocalizeMap = {
    "MR": "Tn.",
    "MRS": "Ny.",
    "MISS": "Nn.",
}

export const salutationList = [
    { name: "Tuan", code: "MR" },
    { name: "Nyonya", code: "MRS" },
    { name: "Nona", code: "MISS" },
];


export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    )
}

export const getEnrollmentId = () => {
    if (isEmpty(JSON.parse(localStorage.getItem("enrollmentId")))) {
        if (!isEmpty(window.location.search.split("=")[1])) {
            localStorage.setItem("enrollmentId", JSON.stringify(window.location.search.split("=")[1]));
        }
    }
    return JSON.parse(localStorage.getItem("enrollmentId"));
};

export const getCustomerContactDetails = () => {
    return JSON.parse(localStorage.getItem('KAI'))
}