/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../../Styles/SelectStation.css";
import { Header, SelectStationDropDown, Loader } from "../index";

import { Button, Typography } from "@mui/material";

import { getAirports } from "../../api/ApiUtils";
import { PopUpModal } from "../../CommonComponents/PopUp/PopUpModal";
import SliderButton from "../../CommonComponents/SliderButton/SliderButton";
import SelectPassengerPopUp from "./SelectPassengerPopUp";
import SelectPassengerClass from "./SelectPassengerClass";
import {
  convertArrayToObjectByKey,
  FLIGHT_TYPES,
  isDateAfter,
  passengerClassMap,
} from "../../common/Utils";
import useLocalstorage, {
  APP_LOCAL_STORAGE_KEYS,
} from "../../hooks/useLocalstorage";
import { DropdownSliderInput } from "../../CommonComponents/DropdownSliderInput/DropdownSliderInput";
import CalendarComponent from "../../CommonComponents/CalendarComponent/CalendarComponent";
import { styled } from "@mui/styles";

export const JOURNEY_TYPE = {
  OneWay: "ONE_WAY",
  RoundTrip: "ROUND_TRIP",
};

const StationSelection = () => {
  const header = "Cari Tiket Pesawat";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [returnTicket, setReturnTicket] = useState(false);

  // STATE'S TO HANDLE THE SLIDERS TO SELECT THE STATION NAMES
  const [startStation, setStartStation] = useState("");
  const [openStartStationDropDown, setOpenStartStationDropDown] =
    useState(false);
  const [stopStation, setStopStation] = useState("");
  const [openStopStationDropDown, setOpenStopStationDropDown] = useState(false);
  const [openPassenger, setOpenPassenger] = useState(false);
  const [openPassengerClass, setOpenPassengerClass] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [passengers, setPassengers] = useState({
    adults: 1,
    infant: 0,
    child: 0,
  });
  const [passengerClass, setPassengerClass] = useState("ECONOMY");
  const [airports, setAirports] = useState([]);
  const { set: setLocalStorageValue } = useLocalstorage();

  useEffect(() => {
    async function fetchAirports() {
      setLoading(true);
      try {
        const resAirports = await getAirports();
        const airPortsByCode = convertArrayToObjectByKey(
          resAirports,
          "airportCode"
        );

        localStorage.setItem("KAI", JSON.stringify({ passengerList: [] }));
        setLocalStorageValue(
          APP_LOCAL_STORAGE_KEYS.airportsByCode,
          airPortsByCode
        );
        setAirports(resAirports);
      } catch (err) {
        console.log(err);
        showErrorPopUp({
          showPopUp: true,
          message: "Something went wrong! Please try again.",
        });
      }
      setLoading(false);
    }
    fetchAirports();
    localStorage.removeItem("INITIAL_FORM_DATA");
    localStorage.removeItem("KAI");
    localStorage.removeItem("ADDON");
    localStorage.removeItem("SELECTED_FLIGHT_DETAILS_ONE_WAY");
    localStorage.removeItem("SELECTED_FLIGHT_DETAILS_ROUND");
    let KAI = {};
    localStorage.setItem("KAI", JSON.stringify(KAI));
  }, []);

  // SET THE DEPARTURE DATE
  const [departureDate, setDepartureDate] = useState(new Date());
  // SET ARRIVAL DATE
  const [arrivalDate, setArrivalDate] = useState(new Date());

  useEffect(() => {
    if (isDateAfter(departureDate, arrivalDate) && returnTicket) {
      setArrivalDate(departureDate);
    }
  }, [departureDate, returnTicket]);

  const [error, setError] = useState(false);
  const handleSubmit = () => {
    if (isDateAfter(departureDate, arrivalDate) && returnTicket) {
      setError(true);
      return;
    }
    if (startStation && stopStation && startStation != stopStation) {
      setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.initialFormData, {
        departureAirportDetails: startStation,
        arrivalAirportDetails: stopStation,
        arrDate: moment(arrivalDate).format("YYYY-MM-DD"),
        depDate: moment(departureDate).format("YYYY-MM-DD"),
        seatClass: passengerClass,
        passengers: {
          adult: passengers.adults,
          child: passengers.child,
          infant: passengers.infant,
        },
        journeyType: returnTicket
          ? JOURNEY_TYPE.RoundTrip
          : JOURNEY_TYPE.OneWay,
      });
      setLocalStorageValue(
        APP_LOCAL_STORAGE_KEYS.flightTypeForListing,
        FLIGHT_TYPES.oneWay
      );
      navigate(`/stationpricecard`);
    } else {
      setError(true);
    }
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  };

  const getLocationLabel = (station) => {
    return station.localCityName + " (" + station.airportCode + ")";
  };

  const getPassengerNumber = () => {
    let passengerValue = "";
    if (passengers.adults > 0) {
      passengerValue = `${passengers.adults} Dewasa`;
    }
    if (passengers.child > 0) {
      passengerValue = passengerValue + `, ${passengers.child} Anak`;
    }
    if (passengers.infant > 0) {
      passengerValue = passengerValue + `, ${passengers.infant} Bayi`;
    }
    return passengerValue;
  };
  const maxSelectableDate = moment(new Date()).add(1, "years").toDate();

  const stationErrorCheck = stopStation != "" && stopStation == startStation;
  const stationErrorCss =
    error && (!stopStation || stopStation == startStation);
  const startStationErrorClass =
    error && !startStation
      ? "selectStationDropdown-error"
      : "selectStationDropdown";
  const stopStationErrorClass =
    error && !stopStation
      ? "selectStationDropdown-error"
      : "selectStationDropdown";
  const stationErrorMessage = stationErrorCheck
    ? "Nama penerbangan tujuan tidak boleh sama dengan penerbangan Asal"
    : "";

  const handleCopyToClipboard = async(text) => {
    await navigator.clipboard.writeText(text)
    console.log('copied!')
  }
  return (
    <div id="StationSelection" className={"StationSelection"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />

      <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
        <div id="StationSelectionForm" className="StationSelectionForm">
          <Header header={header} back={""} />
          <div style={{padding: '0 16px'}}>
            {/* Select Dropdown for Station Names */}
            <DropdownSliderInput
              label={"Penerbangan Asal"}
              value={startStation ? getLocationLabel(startStation) : ""}
              onClickHandler={() => {
                setOpenStartStationDropDown(!openStartStationDropDown);
              }}
              className={startStationErrorClass}
              error={error && !startStation}
              placeholder={"Pilih Penerbangan"}
              errorMessage={"Pilih penerbangan asal terlebih dahulu"}
            />

            <DropdownSliderInput
              label={"Penerbangan Tujuan"}
              className={stopStationErrorClass}
              onClickHandler={() =>
                setOpenStopStationDropDown(!openStopStationDropDown)
              }
              value={stopStation ? getLocationLabel(stopStation) : ""}
              placeholder="Pilih Penerbangan"
              error={stationErrorCss}
              errorMessage={
                error && stopStation == ""
                  ? "Pilih penerbangan tujuan terlebih dahulu"
                  : stationErrorMessage
              }
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <CalendarComponent
                label="Tanggal Keberangkatan"
                disablePast={true}
                inputDate={departureDate}
                maxSelectableDate={maxSelectableDate}
                setDate={setDepartureDate}
              />

              {/* Toggle to display Return Ticiket option */}
              <SliderButton
                returnTicket={returnTicket}
                setReturnTicket={setReturnTicket}
              />
            </div>
            {/* Train Ticiket Return Date */}
            {returnTicket && (
              <div>
                <CalendarComponent
                  label="Tanggal Pulang"
                  disablePast={true}
                  inputDate={arrivalDate}
                  maxSelectableDate={maxSelectableDate}
                  setDate={setArrivalDate}
                  minDate={departureDate}
                />
                <span
                  style={{
                    display:
                      error && isDateAfter(departureDate, arrivalDate)
                        ? ""
                        : "none",
                    color: "#d32f2f",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >{`Pulang date must be >= Pergi date`}</span>
              </div>
            )}

            {/* Passangers */}
            <DropdownSliderInput
              label={"Penumpang"}
              className={"selectStationDropdown"}
              onClickHandler={() => setOpenPassenger(!openPassenger)}
              value={getPassengerNumber()}
            />

            {/* Class */}
            <DropdownSliderInput
              label={"Kelas Penerbangan"}
              className={"selectStationDropdown"}
              onClickHandler={() => setOpenPassengerClass(!openPassengerClass)}
              value={passengerClassMap[passengerClass]}
            />

            <Button
              children={"Cari Tiket Pesawat"}
              fullWidth
              variant={"text"}
              className={"submitButton"}
              onClick={handleSubmit}
              id="CariTiketPesawat"
            />
            <div className="disclaimer">
              <Typography className="terms" onClick={() => handleCopyToClipboard('02129103300')}>
                Produk atau jasa ini disediakan dan menjadi tanggung jawab
                Traveloka. Untuk layanan Customer Service Traveloka, hubungi 021
                29103300.
              </Typography>
              <Typography className="terms">
                Cek Syarat & Ketentuan lengkap{" "}
                <a
                  style={{
                    color: "#1a1a1a",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                  target="_blank"
                  href="https://www.traveloka.com/id-id/termsandconditions"
                >
                  di sini.
                </a>
              </Typography>
            </div>
          </div>
        </div>
      </div>

      {openStartStationDropDown && (
        <SelectStationDropDown
          list={airports}
          slideOpen={openStartStationDropDown}
          setOpenStationDropDown={setOpenStartStationDropDown}
          setStationName={setStartStation}
          title={"Penerbangan Asal"}
        />
      )}
      {openStopStationDropDown && (
        <SelectStationDropDown
          list={airports}
          slideOpen={openStopStationDropDown}
          setOpenStationDropDown={setOpenStopStationDropDown}
          setStationName={setStopStation}
          title={"Penerbangan Tujuan"}
        />
      )}

      <SelectPassengerPopUp
        slideOpen={openPassenger}
        passengers={passengers}
        setPassengers={setPassengers}
        setOpenPassenger={setOpenPassenger}
      />

      <SelectPassengerClass
        value={passengerClass}
        slideOpen={openPassengerClass}
        setOpenPassengerClass={setOpenPassengerClass}
        setPassengerClass={setPassengerClass}
      />

      <Footer>
        <Button children={"Cari Tiket"} variant={"text"} fullWidth />
        <Button children={"Pesanan Anda"} variant={"text"} fullWidth />
      </Footer>
    </div>
  );
};

export default StationSelection;

const Footer = styled('div')({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-evenly',
  position: 'absolute',
  bottom: 0,  
  left: 0,
  right: 0,
  display: 'none'
})
