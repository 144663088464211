import React from "react";

const BaggageSummaryItem = ({summary}) => {
  return (
    <div
      className="bag-select-summary-item"
      
    >
      <div>
        <span className="label-2">{summary.depAirport}</span>-
        <span className="label-2">{summary.arrAirport}</span>
      </div>
      <div className="label-3">Rp {summary.price}</div>
    </div>
  );
};

export default BaggageSummaryItem;
