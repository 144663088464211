import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/styles';

const FooterContainer = styled('div')({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#FFF',
    margin: '0 auto',
    padding: '1rem 0',
    maxWidth: '640px',
    textAlign: 'center',
})

const Footer = ({type}) => {
    if(type === "poweredByAyoconnectAll") { 
        return (
            <FooterContainer>
                <img alt='Powered By Ayoconnect' src={'/images/poweredByAyoconnect.svg'} />
            </FooterContainer>
        )
    }
    if(type === "poweredByAyoconnect") { 
        return (
            <FooterContainer>
                <img alt='Powered By Ayoconnect' src={'/images/poweredByAyoconnectOnly.svg'} />
            </FooterContainer>
        )
    }

    return (
        <div id='footer' className="footer">
            <Typography id='footerText' className="footerText">Didukung oleh:</Typography>
        </div>
    )
}

export default Footer
